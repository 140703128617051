import type * as Types from './types'

const ErrorPage: Types.ErrorPageComponent = (props) => {
    const {
        status,
        title,
        text
    } = props

    return (
        <div className={'error-page-wrapper'}>
            <div className={'error-page-inner-wrapper'}>
                <p className={'error-page-title'}>
                    {`🐣 ${status || 500} - ${title || 'Oops!'}`}
                </p>
                
                <p className={'error-page-text'}>
                    {text || `Something went wrong.`}
                </p>

                <a
                    href={'/'}
                    className={'error-page-anchor'}
                >
                    <button className={'error-page-button'}>
                        {'Go Home'}
                    </button>
                </a>
            </div>
        </div>
    )
}

export default ErrorPage